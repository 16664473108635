<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <div class="subWhite">
        <div class="sub_bg_bot"></div>
        <div class="subTitBox">
          <p id="t_page_title" class="subTitle">{{ $t('buyHistory.title') }}</p>
        </div>
        <div class="RightBox">
          <div id="bonusList">
            <div class="icome-list-history ng-star-inserted">
              <table id="users">
                <thead>
                <tr>
                  <th class="hide"> {{ $t('common.no') }}</th>
                  <th> {{ $t('common.date') }}</th>
                  <th> {{ $t('common.details') }}</th>
                  <th> {{ $t('common.price') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr class="ng-star-inserted" v-for="(item,index) in list " :key="index">
                  <td class="hide"> {{ item.no }}</td>
                  <td> {{ item.date }}</td>
                  <td> {{ $checkPlan(item.plan) }}</td>
                  <td> $ {{ item.balance }}</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
const CryptoJS = require("crypto-js");

export default {
  data() {
    return {
      list: [],
    }
  },
  mounted() {
    this.getBonusDashboard();
    this.getBonusList();

  },
  methods: {
    getBonusList() {
      this.$axios.post('/member/sales/GetHistoryList', {}).then(
          res => {
            if (res.status == 200) {
              if (res.data.code == '200') {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res);
                this.list = body.list;
              } else if (res.data.code == '9999') {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            } else {
              this.loading = false;
            }
          }
      )
    },
    getBonusDashboard() {
      this.$axios.post('/member/bonus/GetBonusDashBoard', {}).then(
          res => {
            if (res.status == 200) {
              if (res.data.code == '200') {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res);
                this.dash_total = body.total;
                this.dash_grade = body.grade;
                this.dash_reffer = body.reffer;
                this.dash_company = body.company;
              } else if (res.data.code == '9999') {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            } else {
              this.loading = false;
            }
          }
      )
    },
  }
}
</script>

<style lang="scss" scoped>
.icome-list-history {
  #users {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 12px 0px;
    border-radius: 15px;
  }
}

@media (min-width: 300px) {
  .hide {
    display: none !important;
  }
  .small-add {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (min-width: 700px) {
  .hide {
    margin: auto;
    display: table-cell !important;
    text-align: center;
  }
  .small-add {
    max-width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .intxt01 {
    width: 70px !important;
  }
}

.show-mobile-menu {
  right: 0;
}

.hide-mobile-menu {
  right: 1;
}
</style>
